import React, {Component} from "react"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField/TextField";
import ArrowLeftIcon from '@material-ui/icons/ArrowBack'
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import {isValidEmail} from "../common/utils/CommonUtils";
import "./join-group.scss"
import { navigate } from "gatsby"

class GetAppToJoin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            email: "",
            success: false,
            error: false,
        }
    }

    sendEmail(email) {
        const {type} = this.props.location.state;
        if (!this.state.error) {
            if (isValidEmail(email)) {
                if (type === 'Providers') {

                    SegmentAnalytics.track(
                        SegmentEvents.USER_REQUESTED_FOR_CONNECT_PROVIDER, {
                            email: email,
                            appRequested: true,
                            providerName: this.props.location.state.providerName
                        });

                } else {
                    SegmentAnalytics.track(
                        SegmentEvents.USER_REQUESTED_FOR_JOIN_GROUP, {
                            email: email,
                            appRequested: true,
                            groupName: 'Group One'
                        });
                }
                this.setState({success: true});

                setTimeout(() => {
                    if (type === 'Providers') {
                        navigate("/connect-provider");
                    } else {
                        navigate("/join-group")
                    }
                }, 1);

            } else {
                console.log("this.state.error", this.state.error);
                this.setState({
                    error: true,
                    success: false,
                    email: ''
                });
            }
        }
    };

    render() {
        return (
            <Layout>
                <div className="join-group-main">
                    <div className="top-card-section"
                         style={{paddingTop: 80, paddingBottom: 80}}>
                        <div className="love-your-home-wrap">
                            <div className="join-group-text-inner">
                                <Button className="back-to-group">
                                    <ArrowLeftIcon
                                        onClick={this.goBack}/>Back</Button>
                                <h2 className="all-group-main-heading">You're in
                                    , now get the App</h2>
                                <p className="all-group-main-para">
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit. Aliquam ut lacus mauris.
                                    Fusce eu purus laoreet, sollicitudin nulla
                                    non, gravida nibh.
                                </p>
                                <div className="input-wrap">
                                    <TextField
                                        id="email-input"
                                        className="phone-input"
                                        placeholder="Enter Your Email"
                                        value={this.state.email}
                                        margin="normal"
                                        onChange={event => {
                                            const {value} = event.target;
                                            this.setState({
                                                email: value,
                                                error: false,
                                                success: false
                                            });
                                        }}
                                    />
                                    <button
                                        className="blue-simple-btn"
                                        color="primary"
                                        onClick={() => {
                                            this.sendEmail(this.state.email)
                                        }}
                                    >
                                        Get the App
                                    </button>
                                </div>
                                {this.state.error ? <p
                                    className="error-msg">Please Enter Valid
                                    Email</p> : null}
                                {this.state.success ? <p
                                    className="success-msg">Subscribed
                                    Successfully </p> : null}
                                <div className="store-btns">
                                    <Button className="itune-btn"
                                            color="primary">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://apps.apple.com/us/app/confidant-health/id1478727129">
                                            <img src={require(
                                                '../assets/images/itunes.svg')}
                                                 alt="itune"/>
                                        </a>
                                    </Button>
                                    <Button className="playstore-btn"
                                            color="primary">
                                        <a target="_blank"
                                           rel="noopener noreferrer"
                                           href="https://play.google.com/store/apps/details?id=live.safeopioidusecoalition.confidant&hl=en">
                                            <img src={require(
                                                '../assets/images/playstore.png')}
                                                 alt="playstore"/>
                                        </a>
                                    </Button>
                                </div>
                            </div>
                        </div>


                        {this.props.location.state && (
                            this.props.location.state.type === 'Groups' ?
                                <div className="join-group-wrapper">
                                    <div className="group-box">
                                        <div className="join-group-info">
                                            <p className="reframing-title">Love
                                                Your Home: <br/>
                                                Reframing Body Image</p>
                                            <div className="join-group-list">
                                                <div className="join-group-row">
                                                    <p className="icon-text">
                                                        <img src={require(
                                                            "../assets/images/calendar-month.svg")}
                                                             alt="compass"/>
                                                        Schedule</p>
                                                    <p className="left-text">Mondays
                                                        at 5 am</p>
                                                </div>
                                                <div className="join-group-row">
                                                    <p className="icon-text">
                                                        <img src={require(
                                                            "../assets/images/time-20-s.svg")}
                                                             alt="compass"/>
                                                        Duration</p>
                                                    <p className="left-text">Usually
                                                        1 hour</p>
                                                </div>
                                                <div className="join-group-row">
                                                    <p className="icon-text">
                                                        <img src={require(
                                                            "../assets/images/dollar-outlined.svg")}
                                                             alt="compass"/>
                                                        Cost</p>
                                                    <p className="left-text">Donation
                                                        based</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <h2 className="all-group-main-heading">{this.props.location.state.providerName}</h2>
                                    <img className="group-organizer-img"
                                         style={{width: 350, borderRadius: 250}}
                                         src={'https:'
                                         + this.props.location.state.providerImage?.file?.url}
                                         alt="Provider Visual"/>
                                </div>
                        )}
                    </div>


                    <div className="group-feature-wrapper">
                        <div className="group-feature-list">
                            <div className="single-group-feature">
                                <img src={require(
                                    '../assets/images/help_icon.svg')}
                                     alt="Icon"/>
                                <div className="group-feature-text">
                                    <h3>Get Support</h3>
                                    <p>You’re not alone in this process. Meet
                                        and
                                        learn from others who’ve been there
                                        themselves.</p>
                                </div>
                            </div>


                            <div className="single-group-feature">
                                <img src={require(
                                    '../assets/images/doctor_icon.svg')}
                                     alt="Icon"/>
                                <div className="group-feature-text">
                                    <h3>Clinical Moderators</h3>
                                    <p>All groups are moderated by trained
                                        healthcare providers, so you can trust
                                        the information
                                        being shared.</p>
                                </div>
                            </div>

                            <div className="single-group-feature">
                                <img src={require(
                                    '../assets/images/dollar_icon.svg')}
                                     alt="Icon"/>
                                <div className="group-feature-text">
                                    <h3>Donation Based</h3>
                                    <p>Only pay for the value you receive from
                                        the groups, nothing else. </p>
                                </div>
                            </div>

                            <div className="single-group-feature">
                                <img src={require(
                                    '../assets/images/cloud_icon.svg')}
                                     alt="Icon"/>
                                <div className="group-feature-text">
                                    <h3>100% Virtual</h3>
                                    <p>Skip the church basement and meet from
                                        the comfort of your couch at home.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </Layout>
        )
    }
}

export default GetAppToJoin
